<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<Toolbar class="p-mb-0">
					<template #left>
						<Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<!--Button label="Töröl" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedDoctors || !selectedDoctors.length" /-->
                        <!--Dropdown v-model="doctorClass" :options="codesDoctorClass" optionLabel="value" optionValue="code" placeholder="Select a Class" /-->
					</template>

					<!--template #right">
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template-->
				</Toolbar>


                <!-- Táblázat -->

				<DataTable ref="dt" :value="doctors" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="5" :filters="filters"
                            sortField="fullname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100,1000]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Karbantartás: Orvosok / Lejelentők</h5>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="prof0_value" header="Besorolás" sortable></Column>
					<Column field="title" header="Titulus" sortable></Column>
					<Column field="fullname" header="Név" sortable></Column>
					<Column field="prof1" header="Szakvizsga" sortable></Column>
					<Column field="prof2" header="Szakterület" sortable></Column>
					<Column field="city" header="Város" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editDoctor(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteDoctor(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.city }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>				
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="doctorDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Orvos / lejelentő" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveDoctor()" @keyup.esc="hideDialog()">
					<div class="p-field">
                        <SelectButton v-model.lazy="v$.doctor.prof0.$model" 
                            required="true" :class="{'p-invalid': v$.doctor.prof0.$invalid }"
                            :options="codesDoctorClass" optionLabel="value" optionValue="code"
                        />
						<small class="p-invalid" v-if="v$.doctor.prof0.$error">{{ errorMessages.required }}</small>
					</div>
					<span class="p-field p-float-label">
						<InputText id="fullname" v-model.trim.lazy="v$.doctor.fullname.$model"
                            required="true" :class="{'p-invalid': v$.doctor.fullname.$invalid }" autocomplete="new-user-fullname"
                        />
						<label for="fullname">Név</label>
						<small class="p-invalid" v-for="e of v$.doctor.fullname.$errors" :key="e.$uid">{{ e.$message }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="title" v-model.trim.lazy="v$.doctor.title.$model"
                            required="true" :class="{'p-invalid': v$.doctor.title.$invalid}" autocomplete="new-user-title"
                        />
						<label for="title">Titulus</label>
						<small class="p-invalid" v-for="e of v$.doctor.title.$errors" :key="e.$uid">{{ e.$message }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="prof1" v-model.trim="doctor.prof1"
                        />
						<label for="prof1">Szakvizsga</label>
					</span>
					<span class="p-field p-float-label">
						<InputText id="prof2" v-model.trim.lazy="v$.doctor.prof2.$model"
                            required="true" :class="{'p-invalid': v$.doctor.prof2.$invalid}" autocomplete="new-user-prof2"
                        />
						<label for="prof2">Szakterület</label>
						<small class="p-invalid" v-for="e of v$.doctor.prof2.$errors" :key="e.$uid">{{ e.$message }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="tel" v-model.trim="doctor.tel" 
                        />
						<label for="tel">Telefon</label>
					</span>
					<span class="p-field p-float-label">
						<InputText id="email" v-model.trim.lazy="doctor.email" :v="v$.doctor.email" @blur="v$.doctor.email.$touch"
                            :class="{'p-invalid': v$.doctor.email.$error}"  autocomplete="new-user-email"
                        />
						<label for="email">Email</label>
						<small class="p-invalid" v-for="e of v$.doctor.email.$errors" :key="e.$uid">{{ e.$message }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="doctor.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveDoctor"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteDoctorDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doctor">Biztosan töröljük az orvost/lejelentőt: <b>{{doctor.fullname}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteDoctorDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteDoctor"/>
					</template>
				</Dialog>

				<!--Dialog v-model:visible="deleteDoctorsDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doctor">Biztosan töröljük az összes kijelölt orvost/lejelentőt?</span>
					</div>
					<template #footer>
						<Button label="Nem" icon="pi pi-times" class="p-button-text" @click="deleteDoctorsDialog = false"/>
						<Button label="Igen" icon="pi pi-check" class="p-button-text" @click="deleteSelectedDoctors" />
					</template>
				</Dialog-->
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DoctorService from '@/service/backend/doctor.service'
import useVuelidate from '@vuelidate/core'
import { required, email, errorMessages } from '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			doctors: null,
			doctorDialog: false,
			deleteDoctorDialog: false,
			deleteDoctorsDialog: false,
			doctor: {},
			expandedRows: [],
			filters: {},
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

		}
	},
    validations: {
        doctor: {
            fullname: { required },
            title: { required }, 
            prof0: { required }, 
            prof2: { required }, 
            email: { email }
        }
    },
    doctorService: null,
	created() {
		this.doctorService = new DoctorService()
    },
    beforeMount() {
        //this.loadCodeStore('doctorClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.lazyParams = {
            first: 0,
            page: 1,
            rows: this.$refs.dt.rows,
            sortField: 'fullname',
            sortOrder: 1,
            filters: null
        };

        this.loadLazyData();
	},
	methods: {
        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }),
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            this.lazyParams.filters = this.filters.global

            this.doctorService.getDoctors(this.lazyParams)
            .then( result => {
                result.data.forEach( d => {d.prof0_value = this.codesDoctorClass.find(c => c.code === d.prof0).value; } ) // Itt töltjük fel, hogy megjelenítéskor legyen csak - TODO valahogy DRY-olni KISS-elni
                this.doctors = result.data
                this.totalRecords = result.totalRecords
                this.loading = false
            })
        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        vCheckRequire(field){
            return this.v$.doctor[field].$dirty && !this.v$.doctor[field].required
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.doctors.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        isAdmin: function(){
            return this.User.role == "ADMIN"
        },
        openDialog(){
            this.doctorDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.doctor = {}
            this.openDialog()
		},
		hideDialog() {
			this.doctorDialog = false
            this.submitted = false
		},
		async saveDoctor() {

            this.submitted = true;
            this.v$.doctor.$touch()

            if (!this.v$.$invalid) {

                    try{
                        if (this.doctor.id) {
                            this.doctor.id = await this.doctorService.updateDoctor(this.doctor)
                            this.doctors[this.findIndexById(this.doctor.id)] = this.doctor
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.doctor.id = await this.doctorService.newDoctor(this.doctor)
                            if(this.doctors.length == this.$refs.dt.rows){
                                this.doctors.pop()
                            }
                            this.doctors.unshift(this.doctor)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch(e){
                        // TODO server oldali hiba kiírása?
                        console.log(e)
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                    }finally{
                        this.submitted = false
                    }

                    // Kódtár alapján érték beállítása
                    this.doctor.prof0_value = this.codesDoctorClass.find(c => c.code === this.doctor.prof0).value 

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editDoctor(id) {
            this.doctor = JSON.parse(JSON.stringify(this.doctors.find( d => d.id === id)));
            this.openDialog()
		},
		confirmDeleteDoctor(doctor) {
			this.doctor = doctor
			this.deleteDoctorDialog = true
		},
		async deleteDoctor() {
            this.submitted = true
            try{
                await this.doctorService.deleteDoctor(this.doctor.id)
                this.doctors = this.doctors.filter(val => val.id !== this.doctor.id)
                this.deleteDoctorDialog = false
                this.doctor = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
		findIndexById(id) { // myArray.findIndex(x => x.id === '45'); ?
			let index = -1
			for (let i = 0; i < this.doctors.length; i++) {
				if (this.doctors[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
		/*confirmDeleteSelected() {
			this.deleteDoctorsDialog = true;
		},
		deleteSelectedDoctors() {
			this.doctors = this.doctors.filter(val => !this.selectedDoctors.includes(val));
			this.deleteDoctorsDialog = false;
			this.selectedDoctors = null;
			this.$toast.add({severity:'success', summary: 'Siker', detail: 'Doctors Deleted', life: 3000});
        },*/
        ctrlEntered() {
            //saveDoctor
            console.log('ctrlEntered()')
        }
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { 
                    this.filters['global'] = val;
                    this.onFilter();
                }, 20)
            }, 600)
        }
    },
    computed: {
        ...mapGetters({
            codesDoctorClass: 'codeStore/doctorClass'
        })
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.doctor-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .doctor-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.doctor-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
