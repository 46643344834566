import ApiService from './api.service'

export default class DoctorService {

    getProductsSmall() {
		//return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	async getDoctors(_params) {
        const response = await ApiService.get('api/doctors', _params)
        return response.data
    }

	async getDoctorsForSelect(_params) {
        const response = await ApiService.get('api/doctors/select', _params)
        return response.data.data
    }

	async newDoctor(doctor) {
        const response = await ApiService.post('api/doctors', doctor)
        return response.data.id
    }

	async updateDoctor(doctor) {
        const response = await ApiService.put('api/doctors/'+doctor.id, doctor)
        return response.data.id
    }

	async deleteDoctor(id) {
        const response = await ApiService.delete('api/doctors/'+id)
        return response
    }

}
